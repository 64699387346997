<template>
    <div>
        <el-tree ref="troubleTree" :data="data" :node-key="nodeKey" :default-expanded-keys="expandedKey"
  :default-checked-keys="checkedKey" :highlight-current="true" accordion
  @node-click="nodeClick" :props="defaultProps"></el-tree>
    </div>
</template>

<script>
export default {
    name: 'trouble-tree',
    props: {
        data: {type: Array, required: true}, //树结构
        expandedKey: {type: Array, required: true}, //默认展开
        checkedKey: {type: Array, required: true}, //默认选中
    },
    watch: {
        data: {
            handler(val) {
                if(val && val.length>0) {
                    let selectId = JSON.parse(localStorage.getItem('troubleTree')).selectId
                    val.map(item => {
                        item.children.map(child => {
                            if(child.id == selectId) {
                                this.$nextTick(()=>{
                                    this.$refs.troubleTree.setCurrentKey(child.id)
                                })
                            }
                        })
                    })
                }
            },
            immediate: true
        },
    },
    data() {
        return {
            nodeKey: 'id',
            defaultProps: {
                children: 'children',
                label: 'label'
            }
        }
    },
    methods: {
        nodeClick(data) {
            if(data && !data.children) {
                let selectId = JSON.parse(localStorage.getItem('troubleTree')).selectId
                this.$nextTick(() => {
                    this.$parent.expandedKey.push(selectId)
                    this.$parent.checkedKey.push(selectId)
                    this.$parent.iframeUrl = '../pdf/web/viewer.html?file='+ encodeURIComponent('/iframe_base/'+data.fileUrl) + '&text=' + encodeURIComponent(data.pageContent)
                })
            }
        }
    }
}  
</script>

<style scoped>
.el-tree {
    background-color: transparent;
}
/deep/.el-tree-node__expand-icon {
    color: #4b5b6b;
    font-size: 16px;
}
/deep/.el-tree-node__content {
    height: 36px;
    border-radius: 5px;
}
/deep/.el-tree-node__content:hover {
    background-color: #e4f0ff;
}
/* /deep/.is-checked .el-tree-node__content {
    background-color: #e4f0ff;
} */
/deep/.el-tree-node__content .el-tree-node__label {
    padding-left: 20px;
    background-image: url(../../assets/icon/icon_file.png);
    background-repeat: no-repeat;
    background-position: 0 3px;
}
/deep/.el-tree-node__children .el-tree-node__content {
    padding-left: 48px !important;
}
/deep/.el-tree-node__children .el-tree-node__content .el-tree-node__label {
    display: inline-block;
    background-image: url(../../assets/icon/icon_pdf.png);
    background-position: 0 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 208px;
    padding-right: 10px;
}
/deep/.el-tree-node__children .el-tree-node__expand-icon {
    display: none;
}
/deep/.el-tree-node__children .is-current .el-tree-node__content {
    background-color: #e4f0ff;
}
</style>