<template>
    <div class="container" id="pgzs">
        <div class="aside" >
           <side-bar ref="sideBar" class="sidebar-container" />
        </div>

        <section class="mainContent">
            <div class="mainContainer">
                <div class="chat-top space-between">
                    <div class="chat-tags"><router-link to="/trouble" class="back"><i class="el-icon-arrow-left" />返回</router-link><span class="grey">排故助手 /</span>文档查看</div>
                    <div class="chat-search-box">
                        <el-input v-model="searchVal" placeholder="search..."></el-input>
                        <span class="chat-search-icon"></span>
                    </div>
                </div>
                
                <div class="chat-mainer space-between">
                    <div class="trouble-side">
                        <trouble-tree ref="stroubleTree" :data="treeData" :expandedKey="expandedKey" :checkedKey="checkedKey"></trouble-tree>
                    </div>
                    <div class="trouble-content">
                        <iframe ref="troubleIframe" :src="iframeUrl" width="100%" height="100%" frameborder="0"></iframe>
                    </div>
                </div>
            </div>
        </section>

        <set-dialog :dialogSetVisible="dialogSetVisible"></set-dialog>
        <info-dialog :dialogSysVisible="dialogSysVisible"></info-dialog>
    </div>
</template>

<script>
import SideBar from '../../components/Sidebar/index.vue'
import InfoDialog from '../../components/Sidebar/InfoDialog.vue';
import SetDialog from '../../components/Sidebar/SetDialog.vue';
import TroubleTree from '../../components/trouble/tree.vue'

export default {
  name:"TroubleList",
  components: {
      SideBar,
      SetDialog,
      TroubleTree,
    InfoDialog
  },
  data() {
    return {
        searchVal: '',
        treeData: [],
        expandedKey: [],
        checkedKey: [],
        defaultProps: {
            children: 'children',
            label: 'label'
        },
        pdfUrl: require('../../assets/icon/icon_pdf.png'),
        iframeUrl: '',
        dialogSetVisible: false,
        dialogSysVisible: false
    }
  },
  created() {},
  mounted() {
     this.$refs.sideBar.handleMenuClick(2);
     this.treeData = JSON.parse(localStorage.getItem('troubleTree')).tree
     this.selectId = JSON.parse(localStorage.getItem('troubleTree')).selectId
     this.treeData.map(item => {
         if(item.children && item.children.length > 0) {
             item.children.map(child => {
                 child.label = child.fileName
                 if(child.id==this.selectId) {
                    this.$refs.stroubleTree.nodeClick(child);
                 }
             })
         }
     })
  },
  methods: {
    
  }
}


</script>

<style scoped>
.chat-top .chat-tags {
    font-size: 14px;
}
.chat-top .chat-tags .back {
    background-color: #e0e4f0;
    border-radius: 8px;
    padding: 2px 5px;
}
.chat-top .chat-tags .grey {
    color: #959796;
     padding: 0 10px;
}
.trouble-side {
    width: 358px;
    height: 100%;
    padding: 20px;
}
.trouble-content {
    background: rgba(255,255,255,.6);
    width: 100%;
    margin: 20px 20px 20px 0;
    border-radius: 15px;
    padding: 15px;
}
/deep/.trouble-content .pdfViewer .page {
    margin: 0 auto !important;
}
</style>